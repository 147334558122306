<template>
  <div class="row">
    <div class="col-12">
      <div class="card concard card-shadow">
        <div class="card-header white text-center">
          <img src="@/assets/logo.png" style="height: 100px" alt="..." />
        </div>
        <div class="card-body mb-47 mt-2 white text-center pb-5">
          <p class="t1 mb-3">
            <strong>แบบสอบถามการประชาคมท้องถิ่น</strong><br>
            <strong>เพื่อรับฟังความคิดเห็นต่อร่างแผนพัฒนาท้องถิ่น</strong><br>
            <strong>เทศบาลนครนครศรีธรรมราช</strong>
          </p>
          <p class="t1 mb-2">
            <strong>รายละเอียดแผนพัฒนาท้องถิ่น</strong>
          </p>

          <i class="bi bi-filetype-pdf mb-4" style="font-size: 8em; color: red;"></i><br>
          <button @click="dowload" class="button mt-3">ดาวน์โหลด</button>
        </div>
        <div class="card-footer white">
          <div class="d-flex justify-center align-center">
            <button @click="next" class="button">ทำแบบสอบถาม</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted, inject } from "vue";
import { SET_REGISTER } from "@/store/modules/RegisterModel";
export default defineComponent({
  name: "Register",
  setup() {
    const store = inject("store");
    const router = inject("router");
    const name_line = ref(process.env.VUE_APP_NAME_LINE);

    onMounted(() => {
      if (!store.getters.getAccessToken.accesstoken) {
        router.push({ name: "Home" });
      }
    });

    const dowload = () => {
      window.open('https://drive.google.com/drive/folders/1u9fzReqIGgZLBs7gXskfulnDG0nHqDBv?usp=sharing', '_blank').focus();
     /// window.open('https://drive.google.com/file/d/10QbqiHw_i-IKQuPSjFtssbuORNuT-TEe/view?usp=sharing', '_blank').focus();
    }


    const next = () => {
      let form = {
        id_line: store.getters.getAccessToken.accesstoken,
        agreement: true,
      };
      store.dispatch(SET_REGISTER, form);
      router.push({ name: "Register_1" });
    };

    return {
      name_line,
      next,
      dowload
    };
  },
});
</script>

<style scoped>
strong {
  font-size: 1.2rem;
}
</style>
